import classNames from "classnames";
import Link from "next/link";

const logos = [
  "partner-logos/givewell.png",
  // "partner-logos/openphil.png",
  "partner-logos/chip.png",
  // "partner-logos/justimpact.png",
  // "partner-logos/stanford.png",
  "partner-logos/ace.png",
  "partner-logos/givinggreen.png",
  // "partner-logos/eafunds.png",
];

export default function Logos() {
  return (
    <div className="w-full mt-24 mb-32 px-4 sm:px-6 lg:px-8">
      <p className="text-center text-base font-semibold uppercase text-slate-600 tracking-wider">
        Built using cutting-edge research from our friends at:
      </p>
      <div className="mt-2 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-4">
        {logos.map((l, i) => {
          return (
            <div key={i} className="col-span-1 flex justify-center py-12 px-8 ">
              <a href="#">
                <img
                  className={classNames(
                    i === 0 ? "max-h-7 mt-2" : "max-h-12",
                    "grayscale hover:grayscale-0 transition-all"
                  )}
                  src={l}
                />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
