import { ChevronRightIcon, StarIcon } from "@heroicons/react/24/solid";

const steps = [
  {
    heading: "Build a portfolio",
    text: "Tell us which causes you care about, and we'll build you a portfolio of Causeway funds — collections of high impact nonprofits.",
  },
  {
    heading: "Set up your contribution",
    text: "Customize your portfolio. We'll distribute your donation to maximize your impact.",
  },
  {
    heading: "Watch your donations make an impact",
    text: "We work with philanthropy experts to ensure that the nonprofits in your portfolio are those with the greatest demonstrated need.",
  },
];

export default function How() {
  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48 sm:max-w-screen-2xl">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2">
          <div className="px-4 sm:px-16 sm:rounded-2xl">
            <div className="max-w-7xl mx-auto">
              <div className="flex flex-col">
                <h2 className="text-2xl md:text-3xl lg:text-4xl sm:mb-14 text-center font-medium text-slate-800">
                  A better way to give...
                </h2>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                  <dl className="space-y-12">
                    {steps.map((step, i) => (
                      <div key={step.heading} className="flex flex-row">
                        <div className="flex-none w-14 leading-6 font-medium text-slate-900">
                          <div className="text-4xl font-bold text-dark-gray-purple">
                            {i + 1}.
                          </div>
                        </div>
                        <div className="">
                          <p className="text-xl font-medium">{step.heading}</p>
                          <p className="text-md text-slate-600">{step.text}</p>
                        </div>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:right-0 lg:inset-y-0 lg:w-1/2">
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:px-0 lg:max-w-none lg:h-full lg:pl-12 2xl:h-5/6 2xl:pt-16">
              <img
                className="w-full 2xl:w-fit rounded-lg border 2xl:border-8 border-black border-opacity-10 lg:h-full lg:w-auto lg:max-w-none"
                src="/screenshot2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
