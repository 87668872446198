import {
  CalculatorIcon,
  ChartPieIcon,
  DocumentChartBarIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Cause-focused funds",
    description:
      "We build funds of highly-effective nonprofits. Our funds are vetted by experts in their fields, so even small donations make a difference. ",
    icon: RectangleStackIcon,
  },
  {
    name: "Personal giving portfolios",
    description:
      "We help you decide which funds to give to, and how much to give. Add any 501(c)(3) nonprofits to your portfolio.",
    icon: ChartPieIcon,
  },
  {
    name: "Monthly impact statements",
    description:
      "See how you're making a difference with a report that shows the estimated impact of your donations.",
    icon: DocumentChartBarIcon,
  },
  {
    name: "Tax receipt combination",
    description:
      "Causeway makes it easy to track your giving by combining all of your donations into a single tax receipt.",
    icon: CalculatorIcon,
  },
];

export default function Features() {
  return (
    <div className="layout mx-4 my-16 py-12 bg-slate-50 md:rounded-xl">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl sm:text-3xl leading-8 font-bold tracking-tight text-slate-800">
            Causeway makes effective giving simple.
          </p>
          <p className="mt-4 max-w-2xl text-xl text-slate-500 lg:mx-auto">
            We&apos;ll work with you to build a thoughtful strategy for
            effective charitable giving.
          </p>
        </div>
        <dl className="mt-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-6">
          {features.map((feature) => (
            <div key={feature.name} className="flex flex-row">
              <div>
                <div className="mt-1 flex items-center justify-center h-14 w-14 text-slate-300">
                  <feature.icon className="h-8 w-8" aria-hidden="true" />
                </div>
              </div>
              <dd className="text-base text-slate-500 rounded-md p-4">
                <p className="text-xl mb-2 leading-6 font-bold text-dark-causeway-purple">
                  {feature.name}
                </p>
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
