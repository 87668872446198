import { Dialog, Popover, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  SupabaseClient,
  useSessionContext,
} from "@supabase/auth-helpers-react";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { Fragment, SetStateAction, useState } from "react";
import NumberFormat from "react-number-format";
import Loading from "../Loading";
import SingleColumn from "../SingleColumn";
import ReactPlayer from "react-player";

// const header = "Your gateway to effective giving.";
// const subheader =
//   "Build a giving portfolio that includes all of the causes you care about. We'll automate it to help maximize your impact.";
const header = ["Donations that make", "a real difference."];
const subheader = [
  "Build a giving portfolio with all of the causes you care about.",
  "We'll work with experts to maximize your impact.",
];

const requestAccess = async (email: string, supabase: SupabaseClient) => {
  const { data, error } = await supabase
    .from("waiting_list")
    .insert([{ email }]);

  if (error) {
    throw error;
  } else {
    return data;
  }
};

export default function Hero() {
  return (
    <div className="relative flex justify-center overflow-hidden bg-gradient-to-t from-slate-100 self-stretch">
      <div className="layout relative sm:pt-14 pb-16 sm:pb-24 lg:pb-32">
        <main className="mx-auto mt-4 max-w-7xl px-4 lg:mt-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-12">
            <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left">
              <h1 className="work-sans text-slate-900 text-3xl font-normal sm:text-4xl md:text-4xl xl:text-5xl">
                <div className="mb-3">Donations that make</div>
                <div className="font-bold">a real difference.</div>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Build a giving portfolio with all of the causes you care about.
                We&apos;ll work with experts to maximize your impact.
              </p>
              <div className="mt-8 sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
                {/* <Link href="/login">
                  <a className="mt-3 w-full border border-transparent bg-causeway-purple hover:bg-dark-gray-purple px-6 py-3 text-base font-medium text-white ocus:outline-none sm:mt-0 sm:inline-flex sm:w-auto sm:flex-shrink-0 sm:items-center">
                    Get Started
                  </a>
                </Link> */}
                {/* <button
                  disabled
                  className="mt-3 w-full border border-transparent bg-causeway-purple px-6 py-3 text-base font-medium text-white ocus:outline-none sm:mt-0 sm:inline-flex sm:w-auto sm:flex-shrink-0 sm:items-center"
                >
                  We&apos;ll Be Back Soon!
                </button> */}
              </div>
            </div>
            <div className="relative mt-12 sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">
              <div className="relative mx-auto w-full pt-[56.25%]">
                <ReactPlayer
                  className="absolute top-0 left-0"
                  url="https://vimeo.com/788065298"
                  playing={true}
                  light="/vidthumbnail.jpg"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
